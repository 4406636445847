<template>
  <div :class="$style.wrap">
    <div :class="$style.header">
      <span class="TC tc-icon-wulianwang1" :class="$style.icon"></span>
      <span :class="$style.title">SD110</span>
    </div>
    <div :class="$style.content">
      <div>
        <img src="~@/assets/images/no-permission.png" alt="" />
        <p>物联网功能目前只针对部分企业开放，敬请期待!</p>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class NoPermission extends Vue {}
</script>

<style lang="less" module>
.wrap {
  margin: 10px;
  border-radius: 6px;
  height: calc(100% - 76px);
  overflow: hidden;
  background: var(--block-bg);
  display: flex;
  flex-direction: column;
  .header {
    height: 50px;
    padding: 0 30px;
    border-bottom: 1px solid var(--border);
    line-height: 50px;
    color: var(--primary);
    .icon {
      font-size: 24px;
      vertical-align: middle;
    }
    .title {
      margin-left: 12px;
      font-size: 16px;
    }
  }
  .content {
    flex: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-bottom: 80px;
    }
    p {
      font-size: 24px;
    }
  }
}
</style>
